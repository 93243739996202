/** @jsx jsx */
import { jsx, Flex, NavLink, Heading } from "theme-ui"
import { Link } from "gatsby"

import useBreakpoints from "../hooks/useBreakpoints"

const StyledHeading = ({ children }) => (
  <Heading
    sx={{
      my: 3,
      pr: 3,
      pb: 1,
      fontSize: 4,
      color: "gray-600",
      borderBottom: "1px solid",
      borderColor: "gray-600",
    }}
  >
    {children}
  </Heading>
)

const StyledLink = ({ children, ...rest }) => (
  <NavLink sx={{ py: 2 }} as={Link} {...rest}>
    {children}
  </NavLink>
)

const MobileMenuLinks = () => {
  const styles = useBreakpoints([
    {
      display: `flex`,
      my: 3,
    },
    {
      display: "none",
    },
  ])
  return (
    <Flex sx={styles}>
      <Flex sx={{ flexDirection: "column" }}>
        <StyledHeading>Application</StyledHeading>
        <NavLink
          sx={{ py: 2 }}
          href="https://sandmbuildings.managebuilding.com/Resident/apps/rentalapp/"
        >
          Apply now
        </NavLink>
        <StyledHeading>Residents</StyledHeading>
        <NavLink
          sx={{ py: 2 }}
          href="https://sandmbuildings.managebuilding.com/Resident/PublicPages/login.aspx"
        >
          Resident portal
        </NavLink>
        <StyledHeading>Contact</StyledHeading>
        <StyledLink to="/contact">Contact us</StyledLink>
      </Flex>
    </Flex>
  )
}

export default MobileMenuLinks
