/** @jsx jsx */
import { jsx } from "theme-ui"
import "hamburgers/dist/hamburgers.min.css"
import "./mobile-menu.css"
import useBreakpoints from "../hooks/useBreakpoints"

const MobileMenu = ({ open, setOpen }) => {
  const displayStyle = useBreakpoints([
    { display: "flex" },
    { display: "none" },
  ])
  const buttonClass = `hamburger hamburger--squeeze ${open ? "is-active" : ""}`

  return (
    <button
      sx={displayStyle}
      className={buttonClass}
      type="button"
      onClick={() => setOpen(!open)}
      aria-label="menu"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}

export default MobileMenu
