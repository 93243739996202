import { useThemeUI } from "theme-ui"

const useBreakpoints = styles => {
  const { theme } = useThemeUI()
  const { breakpoints } = theme

  if (!Array.isArray(styles)) {
    return styles
  }

  const defaultStyle = styles.shift()
  return styles
    .map((style, index) => {
      const key = `@media (min-width:${breakpoints[index]})`
      return {
        [key]: {
          ...style,
        },
      }
    })
    .reduce((responsiveStyles, style) => {
      return {
        ...responsiveStyles,
        ...style,
      }
    }, defaultStyle)
}

export default useBreakpoints
