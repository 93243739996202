/** @jsx jsx */

import React from "react"
import { jsx, Box, Flex } from "theme-ui"

const Dropdown = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        "&:hover > div": {
          display: "flex",
        },
        "&:hover > a": {
          display: "flex",
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const Content = ({ children }) => {
  return (
    <Flex
      sx={{
        display: "none",
        position: "absolute",
        flexDirection: "column",
        pt: [2, 3],
        bg: "background",
        "&:hover": {
          display: "flex",
        },
        borderRadius: "small",
      }}
    >
      {children}
    </Flex>
  )
}
Dropdown.Content = Content
export default Dropdown
