/** @jsx jsx */
import { jsx, NavLink, Flex } from "theme-ui"
import { Link } from "gatsby"

import Dropdown from "./dropdown"
import useBreakpoints from "../hooks/useBreakpoints"

const fonts = { fontSize: [0, 2] }
const margins = { ml: [2, 4] }

const StyledText = (props) => (
  <NavLink as="div" sx={{ ...fonts, ...margins }} {...props} />
)

const BaseNavLink = (props) => <NavLink sx={{ ...fonts }} {...props} />

const StyledNavLink = (props) => <BaseNavLink sx={{ ...margins }} {...props} />

const DropdownLink = (props) => (
  <BaseNavLink
    sx={{
      ml: 2,
      p: [2, 3],
      borderBottom: "2px solid",
      borderColor: "muted",
      borderRadius: "small",
    }}
    {...props}
  />
)

const StyledDropdown = (props) => <Dropdown sx={{ ...fonts }} {...props} />

const MenuLinks = () => {
  const menuStyle = useBreakpoints([
    {
      display: "none",
    },
    {
      display: "flex",
      flexDirection: "row",
    },
  ])

  return (
    <Flex as="nav" sx={menuStyle}>
      <StyledNavLink
        as={Link}
        to="https://sandmbuildings.managebuilding.com/Resident/apps/rentalapp/"
      >
        Apply now
      </StyledNavLink>
      <StyledNavLink href="https://sandmbuildings.managebuilding.com/Resident/PublicPages/login.aspx">
        Resident portal
      </StyledNavLink>
      <StyledNavLink as={Link} to="/contact">
        Contact
      </StyledNavLink>
    </Flex>
  )
}

export default MenuLinks
