/** @jsx jsx */

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Flex, jsx } from "theme-ui"

import Town from "../images/undraw-town.svg"
import Header from "./header"
import "./layout.css"

const Layout = ({ children, fullWidth = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Flex sx={{ flexFlow: "column", minHeight: "100vh" }}>
      <Container px={3}>
        <Header siteTitle={data.site.siteMetadata.title} />
      </Container>
      <div sx={{ flex: "1 1 auto" }}>
        {fullWidth ? (
          <main>{children}</main>
        ) : (
          <Container px={3}>
            <main>{children}</main>
          </Container>
        )}
      </div>
      <Container>
        <Town sx={{ maxWidth: "100%" }} />
      </Container>
      <Container sx={{ px: [3], mt: [0, 5] }}>
        <footer>© {new Date().getFullYear()}, S&M Building Management</footer>
      </Container>
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
