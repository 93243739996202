/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import { Flex, Image } from "theme-ui"
import { Link, graphql, useStaticQuery } from "gatsby"

import MenuLinks from "./menu-links"
import MobileMenu from "./mobile-menu"
import MobileMenuLinks from "./mobile-menu-links"
import useBreakpoints from "../hooks/useBreakpoints"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "sm-logo.png" }) {
        publicURL
      }
    }
  `)
  const imageStyle = useBreakpoints([
    {
      width: "90px",
    },
    {
      width: "120px",
    },
  ])

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header
      sx={{
        py: 2,
        position: "relative",
        zIndex: 1,
      }}
    >
      <Flex
        sx={{
          justifyContent: `space-between`,
          alignItems: `center`,
        }}
      >
        <Flex as={Link} to="/" sx={{ alignItems: "flex-start" }}>
          <Image
            sx={{ ...imageStyle }}
            src={`${data.logo.publicURL}`}
            alt="S&M logo"
          />
        </Flex>
        <MenuLinks />
        <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
      </Flex>
      {mobileMenuOpen && <MobileMenuLinks />}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
